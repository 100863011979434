import React, {useMemo} from 'react'
import getData from './data'
import {getCategoryMapping} from "./helpers/category";
import {getCsvData, getCsvHeaders} from "./helpers/csv";
import {CSVLink} from "react-csv";
import {Table} from "./table";
import PdfList from "./pdfList";
import {faDownload} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function PriceList({products, attributeData}) {
    const tables = getTables(products, attributeData)

    const csvHeaders = getCsvHeaders()
    const csvData = getCsvData(tables)

    return (
        <div className={"container"}>
            <div className={"inner"}>
                <h1>Oxford Nanopore Technologies Price List</h1>
                <div className={"download-first"}>
                    <PdfList tables={tables}/>
                </div>
                <div className={"download"}>
                    <CSVLink data={csvData} headers={csvHeaders} filename="ont_pricelist.csv">
                        <FontAwesomeIcon className={"dlIcon"} icon={faDownload} size={"3x"} color={"#0084a9"}/>
                        Download CSV
                    </CSVLink>
                </div>
                <div className={"clear"}/>

                {tables.map((table) =>
                    <Table key={table.category} heading={table.category} data={table.data} />
                )}
                <style jsx>{`
                    .download {
                      margin: 20px 0 20px 20px;
                      float: left;
                    }
                    .download-first {
                      margin: 20px 0 20px 0;
                      float: left;
                    }
                    .clear {
                      clear:left;
                    }
                    .container {
                      max-width: 1200px;
                      margin: 0 50px 0 50px;
                      padding: 0 50px 0 50px;

                    }
                    .inner {
                      display: table;
                      margin: 20px auto;
                    }
                    .dlIcon {
                      margin-right: 10px;
                      left: 10px;
                    }
                `}</style>
            </div>
        </div>
    )
}

function getTables(products, attributeData) {
    const tables = [];
    const categoryMapping = useMemo(() => getCategoryMapping(products) , [])

    for (const [category, products] of Object.entries(categoryMapping)) {
        if (!products.length > 0) {
            continue;
        }
        tables.push({
            category: category,
            data: getData(products, attributeData)
        })
    }

    return tables;
}

export default PriceList
