export function getCategoryMapping(products) {
    return {
        'MinION':
            getCategoryProducts(products, /devices\/minion$/),

        'MinION Mk1C':
            getCategoryProducts(products, /devices\/minion-mk1c$/),

        'GridION':
            getCategoryProducts(products, /devices\/gridion$/),

        'PromethION':
            getCategoryProducts(products, /devices\/promethion$/),

        'P2i':
            getCategoryProducts(products, /devices\/p2$/),

        'P2 Solo':
            getCategoryProducts(products, /devices\/p2-solo$/),

        'Flongle':
            getCategoryProducts(products, /devices\/flongle$/),

        'Elysion':
            getCategoryProducts(products, /devices\/elysion$/),

        'Devices':
            getCategoryProducts(products, /devices$/),

        'Sequencing Kits':
            getCategoryProducts(products, /sample-prep\/sequencing-kits/),

        'Expansion Packs':
            getCategoryProducts(products, /sample-prep\/expansion-packs/),

        'Flow Cells':
            getCategoryProducts(products, /\/flow-cells/),

        'ElysION: Plasticware':
            getCategoryProducts(products, /\/elysion-plasticware/),

        'ElysION: Sample prep':
            getCategoryProducts(products, /\/elysion-sample-prep/),

        'ElysION: Flow cells':
            getCategoryProducts(products, /\/elysion-flow-cells/),

        'MinION 10 Year':
            getCategoryProducts(products, /consumables\/minion-10-year$/),

        'Legacy: Sample prep':
            getCategoryProducts(products, /legacy\/legacy-sample-prep/),

        'Legacy: Flow cells':
            getCategoryProducts(products, /legacy\/legacy-flow-cells/),

        'Training':
            getCategoryProducts(products, /training/),

        'Services - Certification':
            getCategoryProducts(products,/serv ices\/certification/),

        'Services - Device Warranty':
            getCategoryProducts(products,/services\/device-warranty/),

        'Services - Analysis':
            getCategoryProducts(products,/services\/analysis/),

        'VolTRAX':
            getCategoryProducts(products,/voltrax/),

        'Q-Line':
            getCategoryProducts(products,/early-access-programmes\/q-line/),

        'High Duplex':
            getCategoryProducts(products,/early-access-programmes\/high-duplex/),

        'Education Beta':
            getCategoryProducts(products,/early-access-programmes\/education-beta/),

        'RNA':
            getCategoryProducts(products,/early-access-programmes\/rna/),

        'T2T':
            getCategoryProducts(products,/early-access-programmes\/t2t/),

        'Omixon':
            getCategoryProducts(products,/partners\/omixon/),

        'KNCV':
            getCategoryProducts(products,/partners\/kncv/),

        '4Bases':
            getCategoryProducts(products,/partners\/4bases/),

        'Scentian BIO':
            getCategoryProducts(products,/partners\/scentianbio/),

        'Moderna':
            getCategoryProducts(products,/partners\/moderna/),

        'PathoQuest':
            getCategoryProducts(products,/partners\/pathoquest/),

        'Asuragen':
            getCategoryProducts(products,/partners\/asuragen/),

        'PathoSense':
            getCategoryProducts(products,/partners\/pathosense/),

        // 'Training':
        //     getCategoryProducts(products, categories, /training/)
    }
}

function getCategoryProducts (products, categoryUrlRegEx) {
    return products.filter(product => {
        return product.categories.some(category => {
            return category.url_path?.match(categoryUrlRegEx)
        })
    })
}
