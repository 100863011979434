import React from 'react';

import PriceList from "../components/PriceList";
import {gql, useQuery} from "@apollo/client";
import {useCustomerContext} from "../contexts/CustomerContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {isTransactional} from "../hooks/siteData";
import Seo from "../components/Seo/seo";

export default function PriceListPage({pageContext}) {
    const {customer} = useCustomerContext();
    const loggedIn = !!customer.token?.length;

    const {error, loading, data} = useQuery(query, {
        fetchPolicy: 'network-only',
        skip: !loggedIn,
    });

    if (!isTransactional()) {
        return null;
    }

    if (!loggedIn) {
        return <PriceList products={pageContext.products} />;
    }

    if (data) {
        const products = getProductsFromCategories(data);
        return <PriceList
            products={products}
            attributeData={data.customAttributeMetadata}
        />;
    }

    if (loading) {
        return <center><FontAwesomeIcon icon={faSpinner} spin /></center>;
    }

    if (error) {
        return <div>Sorry, there was an error loading the price list.</div>
    }
}

export const Head = ({pageContext}) => {
    return <Seo title={"Price List"}/>;
}

// duplication necessary to avoid performance hit on SSR built page
function getProductsFromCategories(data) {
    const products = [];
    const unique = [];
    for (const category of data.categoryList) {
        if (category.name == 'Default Category') continue;
        for (const cp of category.products.items) {
            if (!unique.includes(cp.sku)) {
                products.push(cp);
                unique.push(cp.sku);
            }
        }
    }
    return products;
}

const query = gql`
fragment ProductFields on ProductInterface {
  id
  name
  sku
  ont_mask_sku
  url_key
  ont_render_type
  short_description {
    html
  }
  ont_price_list_description
  ont_whats_in_the_box
  price {
    regularPrice {
      amount {
        value
        currency
      }
    }
  }
  price_range {
    maximum_price {
      final_price {
        currency
        value
      }
    }
  }
  ont_custom_price {
    currency
    value
    expires_at
  }
  __typename
  url_path
  url_key
  url_rewrites {
    url
    parameters {
      name
      value
    }
  }
  categories {
    id
    name
    url_path
  }
  category_siblings {
    url_key
  }
}

fragment ConfigurableProductFields on ConfigurableProduct {
  variants {
    attributes {
      code
      uid
      value_index
    }
    product {
      id
      name
      ont_render_type
      sku
      ont_mask_sku
      ont_pack_size
      ont_custom_price {
        currency
        value
        expires_at
      }
      ont_tier_price {
        tier_unit_price
        tier_expiry
      }
      price {
        regularPrice {
          amount {
            currency
            value
          }
        }
      }
    }
  }
}



{
    customAttributeMetadata(
      attributes: [
        {
          attribute_code: "ont_pack_size"
          entity_type: "catalog_product"
        }
      ]
    ) {
      items {
        attribute_code
        attribute_options {
          label
          value
        }
      }
    }

    categoryList(pageSize: 100, filters: {}) {
      name
      products(pageSize: 1000) {
        items {
          ...ProductFields
          ...ConfigurableProductFields
        }
        total_count
      }
    }
}
`;
